<ng-container *ngIf="header; else hTemplate">
	<ng-container *ngTemplateOutlet="header.template; context: { $implicit: header.context }"></ng-container>
</ng-container>
<ng-template #hTemplate>
	<ng-container *ngIf="headerTemplate">
		<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
	</ng-container>
</ng-template>

<table cdk-table *ngIf="dSource" [dataSource]="dSource">
	<ng-container *ngFor="let column of columns$ | async">
		<ng-container [cdkColumnDef]="column.columnDef">
			<th cdk-header-cell *cdkHeaderCellDef [class]="column.thClass">
				{{ column.header | translate }}
			</th>
			<td cdk-cell *cdkCellDef="let row" [class]="column.tdClass">
				<ng-container *ngIf="hasTemplate(column); else base">
					<ng-container *ngTemplateOutlet="column.template; context: { $implicit: row }"></ng-container>
				</ng-container>
				<ng-template #base>
					<ng-container *ngIf="column.columnDef === 'check'; else std">
						<addiction-checkbox [value]="isRowChecked(column, row)" (valueChange)="checkRow(row, $event)"></addiction-checkbox>
					</ng-container>

					<ng-template #std>
						<ng-container *ngIf="!hasTemplate(column)">
							{{ column.cell(row) }}
						</ng-container>
					</ng-template>
				</ng-template>
			</td>
		</ng-container>
	</ng-container>

	<tr cdk-header-row *cdkHeaderRowDef="displayedColumns | async"></tr>
	<tr cdk-row *cdkRowDef="let row; columns: displayedColumns | async"></tr>
</table>

<ng-container *ngIf="noItemsTemplate && !rowCount">
	<ng-container *ngTemplateOutlet="noItemsTemplate"></ng-container>
</ng-container>

<ng-container>
	<datalean-paginator
		[pageSize]="pageSize"
		[currentPage]="curPage - 1"
		[totalItems]="rowCount"
		(pageChange)="onPageChange($event)"></datalean-paginator>
</ng-container>
