import { Injectable, inject } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import {
	DataleanBaseApiService,
	DataleanPagedResult,
	DataleanSelectOption,
	FeatureValue,
	FeatureValueType,
	ModalService,
	PaginationInfo,
	Parts,
	SimpleObject,
	SortInfo,
	Structure,
	StructureType,
} from 'addiction-components';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { CommunicationActions, UsefulLinksActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { Communication } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';
import { ImportExportDialogComponent } from '../../../shared/components/import-export-dialog/import-export-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class CommunicationService {
	private store = inject(Store);
	private headerSrv = inject(HeaderLocaleService);
	private baseApi = inject(DataleanBaseApiService);
	private modalSrv = inject(ModalService);
	private translate = inject(TranslateService);

	private destroyed$ = new Subject();
	private lastCommunitySelected$ = this.store.select(CommunitySelectSelectors.selectLastCommunitySelected).pipe(takeUntil(this.destroyed$));

	constructor() {}

	openImportExportModal(structures: DataleanSelectOption<string>[] | null | undefined) {
		const $openModalRes = this.modalSrv.openDialog(
			ImportExportDialogComponent,
			{ title: this.translate.instant('MODALS.IMPORT_EXPORT.MODAL_TITLE') },
			{
				showExport: true,
				showImport: true,
				endpointImport: environment.communicationUrl + 'import/',
				endpointExport: environment.communicationUrl + 'export/',
				negativeReflection: true,
				structures,
			}
		);
		return $openModalRes.result$;
	}
	init(structureType: StructureType): void {
		this.lastCommunitySelected$.pipe(takeUntil(this.destroyed$)).subscribe((communityUUID) => {
			this.getStructureAndFeatures(structureType, communityUUID);
		});
	}

	getStructureAndFeatures(structureType: StructureType, communityUUID?: string) {
		const structureAdditionalParams: {
			type?: StructureType;
		} = { type: structureType };

		const featureAdditionalParams: {
			featureValueCommunityUUID?: string;
			type?: FeatureValueType;
			locale?: string;
			parentUUID: string;
		} = { type: FeatureValueType.COMMUNICATION, locale: this.translate.currentLang ?? this.translate.defaultLang , parentUUID: ''};

		// TODO ATTIVARE QUANDO GESTITE LE COMUNITA'
		// if (communityUUID !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = communityUUID;
		// } else if (this.lastCommunitySelected !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = this.lastCommunitySelected;
		// }

		this.baseApi
			.getMany<Structure>(environment.structureUrl, [Parts.EMPTY], { additionalParams: structureAdditionalParams })
			.subscribe((structures) => {
				if (structureType === StructureType.COMMUNICATION) this.store.dispatch(CommunicationActions.setStructures({ structures }));
				if (structureType === StructureType.COCKPIT) this.store.dispatch(UsefulLinksActions.setStructures({ structures }));
			});
		this.baseApi
			.getMany<FeatureValue>(environment.featureValueUrl, [Parts.FEATURE_VALUES], {
				additionalParams: featureAdditionalParams,
			})
			.subscribe((features) => {
				this.store.dispatch(CommunicationActions.setFeatures({ features }));
			});
	}

	getCommunications(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: {
			uuids?: string[];
			structureUUID?: string;
			query?: string;
			'structure.type'?: StructureType;
			communityUUID?: string;
			surveyUUID?: string;
		};
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.['structure.type']) params['structure.type'] = filters['structure.type'];
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters?.communityUUID;
		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		if (filters?.surveyUUID) params['surveyUUID'] = filters.surveyUUID;

		return this.baseApi.getManyPaged<Communication>(environment.communicationUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	getStructures(parts: Parts[]) {
		if (!parts) parts = [Parts.EMPTY];

		return this.baseApi.getManyPaged<Structure>(environment.structureUrl, parts);
	}

	countCommunications(filters: { structureUUID?: string; surveyUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(environment.communicationUrl + 'count', '', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	deleteCommunication(uuid: string, structureType: StructureType) {
		return this.baseApi.deleteEntity(environment.communicationUrl, { uuid }).pipe(
			tap(() => {
				if (structureType === StructureType.COMMUNICATION) this.store.dispatch(CommunicationActions.setForceRefreshCommunications());
				if (structureType === StructureType.COCKPIT) this.store.dispatch(UsefulLinksActions.setForceRefreshUsefulLinks());
			})
		);
	}

	public multipleUpdate(bulkPayload: { uuidList: string[]; delete?: boolean; active?: boolean }) {
		return this.baseApi.updateBulk(environment.communicationUrl + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}

	fetchCommunications(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string; 'structure.type'?: StructureType; communityUUID?: string }
	): Observable<DataleanPagedResult<Communication>[]> {
		const obs: Observable<DataleanPagedResult<Communication>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
			structureUUID?: string;
			featureValueList?: string;
			'structure.type'?: StructureType;
			communityUUID?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
			sortBy: 'updateDate#desc',
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		if (filters?.['structure.type']) params['structure.type'] = filters['structure.type'];
		if (filters?.['communityUUID']) params['communityUUID'] = filters.communityUUID;
		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Communication>(
					environment.communicationUrl,
					[Parts.SUBJECT_USERS_COUNT, Parts.OPEN_COUNT, Parts.CONFIRM_COUNT, Parts.UPDATE_DATE],
					{
						pagination,
						additionalParams: params,
					}
				)
			);
		}
		return forkJoin(obs);
	}
}
